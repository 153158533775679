<template>
    <div class="setting_wpr">
        <div class="section_wpr">
            <h3>Connection Tools<button type="button" @click="editNote = true">Add Note</button></h3>
            <ul>
                <li>
                    <div class="card_item border-0" id="sms" @click="handleAssetAction('smsItem', 1)"><img src="@/assets/images/sms.svg">Send SMS</div>
                </li>
                <li>
                    <div class="card_item border-0" id="email" @click="handleAssetAction('emailItem', 2)"><img src="@/assets/images/mail.svg">Send Email</div>
                </li>
                <li>
                    <div class="card_item border-0" @click="handleAssetAction('tempItem', 2)"><img src="@/assets/images/template.svg">Send Template</div>
                </li>
                <li>
                    <div class="card_item border-0" id="portal-msg" @click="handlePortalMessage()"><img src="@/assets/images/sms.svg">Send Portal Message</div>
                </li>
                <li>
                    <div class="card_item border-0" @click="handleAssetAction('sequenceItem', 3)"><img src="@/assets/images/sequences2.svg">Start Sequence</div>
                </li>
            </ul>
        </div>
        <div v-if="editNote" class="edit_section mb-4">
            <Form @submit="handleAddNote" v-slot="{ errors }" ref="launchpad-send-email-form">
                <div class="section_title mb-4">
                    Add Note
                    <div class="dropdown">
                        <i class="fas fa-ellipsis-v" @click="noteDropdown = !noteDropdown"></i>
                        <div class="drp_wrapper" v-if="noteDropdown" :class="{ active : noteDropdown }">
                            <ul>
                                <li>Reset to default message</li>
                                <li @click="templateLibrary = !templateLibrary; noteDropdown = false;" :class="{ active : templateLibrary }">Import Template</li>
                                <li>Save as template</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="editorWpr" :class="{ 'has-error': errors.note }">
                    <Field autocomplete="off" name="note" v-model="note" :class="{ 'has-error': errors.note }" rules="required">
                        <redactor v-model="note" name="note" :default-value="noteDefaultValue" :reset-watcher="noteResetWatcher" />
                    </Field>
                </div>
                <ErrorMessage name="note" class="text-danger" />
                <template-library v-model="templateLibrary" :on-import="handleTemplateImport" type="content" />
                <div class="action_wpr mt-4">
                    <button :disabled="noteLoader" class="btn cancel_btn" @click="editNote = false">Cancel</button>
                    <button :disabled="noteLoader" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="noteLoader"></i> {{ noteLoader ? 'Saving' : 'Save' }}</button>
                </div>
            </Form>
            <div class="tempcard">
                <ul>
                    <li v-for="(noteObj, n) of notes.data" :key="n">
                        <div class="tempcard_details" v-if="noteObj">
                            <h5 v-html="noteObj.note.length > 35 ? noteObj.note.substr(0, 35) + '...' : noteObj.note"></h5>
                        </div>
                        <span class="view" @click="toggleEditNote(noteObj)">
                            <i class="fas fa-edit"></i>
                        </span>
                    </li>
                </ul>
                <div class="pagination mt-2" v-show="notes.total">
                    <pagination v-model="noteParams.page" :pages="notes.last_page" @update:modelValue="handleNotePagination" />
                </div>
            </div>
        </div>
        <div class="section_wpr">
            <h3>Coaching Tools</h3>
            <ul>
                <li>
                    <div class="card_item border-0" @click="handleAssetAction('playbookItem', 3)"><img src="@/assets/images/playbook.svg">Assign Playbook</div>
                </li>
                <li>
                    <div class="card_item border-0" @click="handleAssetAction('pagesItem', 3)"><img src="@/assets/images/page.svg">Assign Page</div>
                </li>
                <li>
                    <div class="card_item border-0" @click="handleAssetAction('formsItem', 3)"><img src="@/assets/images/form.svg">Send Form</div>
                </li>
                <li>
                    <div class="card_item border-0" @click="handleAssetAction('habitItem', 3)"><img src="@/assets/images/habit_tracking.svg">Start Habit Tracking</div>
                </li>
                <li>
                    <div class="card_item border-0" @click="handleAssetAction('progressItem', 3)"><img src="@/assets/images/progress_tracking.svg">Start Client Tracking </div>
                </li>
                <li>
                    <div class="card_item border-0" @click="handleAssetAction('filesItem', 3)"><img src="@/assets/images/folder2.svg">Add File</div>
                </li>
                <li>
                    <div class="card_item border-0" @click="handleAssetAction('rewardsItem', 3)"><img src="@/assets/images/reward.svg">Add Rewards</div>
                </li>
                <li>
                    <div class="card_item border-0" @click="handleAssetAction('journeyItem', 3)"><img src="@/assets/images/journey.svg">Start Journey</div>
                </li>
                <li>
                    <div class="card_item border-0" @click="handleAssetAction('workoutItem', 3)"><img src="@/assets/images/workout_2.svg">Assign Workout</div>
                </li>
                <li>
                    <div class="card_item border-0" @click="handleAssetAction('workoutPlanItem', 3)"><img src="@/assets/images/workout_plan.svg">Assign a Workout Program</div>
                </li>
            </ul>
        </div>
        <div v-if="smsItem" class="details_wpr" @click.self="resetItems()">
            <div class="cardItem_details">
                <div class="header">
                    <button class="close_btn" @click="smsItem = false"><i class="fas fa-chevron-down"></i></button>
                    <h2>Send SMS</h2>
                </div>
                <div class="mb-5">
                    <contact-sms-component is-contact v-model="smsText" :number="number" :contacts="contact.id" ref="sms-component" />
                </div>
                <div class="action_wpr fixed_footer">
                    <button :disabled="smsLoader" type="button" class="btn cancel_btn" @click="smsItem = false">cancel</button>
                    <button :disabled="smsLoader" class="btn save_btn" @click="handleSMSSend"><i class="fa fa-spinner fa-spin" v-if="smsLoader"></i> {{ smsLoader ? 'Sending' : 'Send' }}</button>
                </div>
            </div>
        </div>
        <div v-if="emailItem" class="details_wpr email-send-wpr" @click.self="resetItems()">
            <Form @submit="handleEmailSend" v-slot="{ errors }" class="cardItem_details">
                <div class="header">
                    <button class="close_btn" @click="emailItem = false"><i class="fas fa-chevron-down"></i></button>
                    <h2>Send Email</h2>
                </div>
                <div class="mb-5">
                    <contact-email-component is-recipient v-model="emailText" :contacts="contact.id" :errors="errors" ref="email-component" />
                </div>
                <div class="action_wpr fixed_footer">
                    <button type="button" :disabled="emailLoader" class="btn cancel_btn" @click="emailItem = false">Cancel</button>
                    <button type="button" :disabled="emailLoader" @click="handleEmailSend" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="emailLoader"></i> {{ emailLoader ? 'Sending' : 'Send' }}</button>
                </div>
            </Form>
        </div>
        <div v-if="tempItem" class="details_wpr" @click.self="resetItems()">
            <div class="cardItem_details">
                <div class="header">
                    <button class="close_btn" @click="tempItem = false"><i class="fas fa-chevron-down"></i></button>
                    <h2>Send Templates</h2>
                </div>
                <div class="mb-5">
                    <template-component :contacts="contact.id" ref="template-component" />
                </div>
                <div class="action_wpr fixed_footer">
                    <button :disabled="templateLoader" class="btn cancel_btn" @click="tempItem = false">Cancel</button>
                    <button :disabled="templateLoader" class="btn save_btn" @click="handleTemplateSend"><i class="fa fa-spinner fa-spin" v-if="templateLoader"></i> {{ templateLoader ? 'Sending' : 'Send' }}</button>
                </div>
            </div>
        </div>
        <div v-if="portalMessage" class="details_wpr" @click.self="portalMessage = false">
            <div class="cardItem_details">
                <div class="header">
                    <button class="close_btn" @click="portalMessage = false"><i class="fas fa-chevron-down"></i></button>
                    <h2>Send Portal Message</h2>
                </div>
                <div class="mb-5">
                    <portal-message :contacts="contact.id" :success-handler="closePortalMessage" ref="portal-message-component" />
                </div>
                <div class="action_wpr fixed_footer">
                    <button :disabled="portalMessageLoader" type="button" class="btn cancel_btn" @click="portalMessage = false">cancel</button>
                    <button :disabled="portalMessageLoader" class="btn save_btn" @click="handleSendMessage"><i class="fa fa-spinner fa-spin" v-if="portalMessageLoader"></i> {{ portalMessageLoader ? 'Sending' : 'Send' }}</button>
                </div>
            </div>
        </div>
        <div v-if="playbookItem" class="details_wpr" @click.self="resetItems()">
            <Form @submit="handlePlaybookSend" v-slot="{ errors }" ref="launchpad-send-playbook-form" class="cardItem_details">
                <div class="header">
                    <button class="close_btn" @click="playbookItem = false"><i class="fas fa-chevron-down"></i></button>
                    <h2>Assign Playbooks</h2>
                </div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Select My Playbooks</label>
                        <div class="field_wpr" :class="{ 'has-error': errors.playbook }">
                            <Field autocomplete="off" name="playbook" v-model="playbook.playbook_id" :class="{ 'has-error': errors.playbook }" rules="required">
                                <multiselect
                                    v-model="playbook.playbook_id"
                                    :options="playbooks"
                                    value-prop="id"
                                    label="title"
                                    :searchable="true"
                                    placeholder="Select playbook"
                                    mode="tags"
                                ></multiselect>
                            </Field>
                        </div>
                        <ErrorMessage name="playbook" class="text-danger" />
                    </div>
                </div>
                <div v-show="playbook.playbook_id.length">
                    <h3>Sending Method</h3>
                    <div class="edit_section">
                        <sending-method v-model="playbook.sending_method" />
                    </div>
                </div>
                <div class="action_wpr fixed_footer">
                    <button type="button" :disabled="playbookLoader" class="btn cancel_btn" @click="playbookItem = false">Cancel</button>
                    <button :disabled="playbookLoader" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="playbookLoader"></i> {{ playbookLoader ? 'Sending' : 'Send' }}</button>
                </div>
            </Form>
        </div>
        <div v-if="pagesItem" class="details_wpr" @click.self="resetItems()">
            <Form @submit="handlePageSend" v-slot="{ errors }" ref="launchpad-send-page-form" class="cardItem_details">
                <div class="header">
                    <button class="close_btn" @click="pagesItem = false"><i class="fas fa-chevron-down"></i></button>
                    <h2>Send Pages</h2>
                </div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Select Pages</label>
                        <div class="field_wpr" :class="{ 'has-error': errors.page }">
                            <Field autocomplete="off" name="page" v-model="page.page_id" :class="{ 'has-error': errors.page }" rules="required">
                                <multiselect
                                    v-model="page.page_id"
                                    :options="pages"
                                    value-prop="id"
                                    label="title"
                                    :searchable="true"
                                    placeholder="Select page"
                                    mode="tags"
                                ></multiselect>
                            </Field>
                        </div>
                        <ErrorMessage name="page" class="text-danger" />
                    </div>
                </div>
                <div v-show="page.page_id.length">
                    <label for="pageshow" class="switch_option capsule_btn">
                        <h5>Show in Client Portal</h5>
                        <input type="checkbox" :true-value="1" :false-value="0" id="pageshow" v-model="page.add_in_client_portal" hidden>
                        <div><span></span></div>
                    </label>
                    <h3>Notification Method</h3>
                    <div class="edit_section">
                        <sending-method v-model="page.sending_method" />
                    </div>
                </div>
                <div class="action_wpr fixed_footer">
                    <button type="button" :disabled="pageLoader" class="btn cancel_btn" @click="pagesItem = false">Cancel</button>
                    <button :disabled="pageLoader" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="pageLoader"></i> {{ pageLoader ? 'Sending' : 'Send' }}</button>
                </div>
            </Form>
        </div>
        <div v-if="formsItem" class="details_wpr" @click.self="resetItems()">
            <Form @submit="handleFormSend" v-slot="{ errors }" ref="launchpad-send-form-form" class="cardItem_details">
                <div class="header">
                    <button class="close_btn" @click="formsItem = false"><i class="fas fa-chevron-down"></i></button>
                    <h2>Send Forms</h2>
                </div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Select Forms</label>
                        <div class="field_wpr" :class="{ 'has-error': errors.form }">
                            <Field autocomplete="off" name="form" v-model="form.form_id" :class="{ 'has-error': errors.form }" rules="required">
                                <multiselect
                                    v-model="form.form_id"
                                    :options="forms"
                                    value-prop="id"
                                    label="title"
                                    :searchable="true"
                                    placeholder="Select form"
                                    mode="tags"
                                ></multiselect>
                            </Field>
                        </div>
                        <ErrorMessage name="form" class="text-danger" />
                    </div>
                </div>
                <div v-show="form.form_id.length">
                    <label for="formshow" class="switch_option capsule_btn">
                        <h5>Show in Client Portal</h5>
                        <input type="checkbox" :true-value="1" :false-value="0" id="formshow" v-model="form.add_in_client_portal" hidden>
                        <div><span></span></div>
                    </label>
                    <h3>Sending Method</h3>
                    <div class="edit_section">
                        <sending-method v-model="form.sending_method" />
                    </div>
                </div>
                <div class="action_wpr fixed_footer">
                    <button type="button" :disabled="formLoader" class="btn cancel_btn" @click="formsItem = false">Cancel</button>
                    <button :disabled="formLoader" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="formLoader"></i> {{ formLoader ? 'Sending' : 'Send' }}</button>
                </div>
            </Form>
        </div>
        <div v-if="habitItem" class="details_wpr" @click.self="resetItems()">
            <Form @submit="handleStartHabit" v-slot="{ errors }" ref="launchpad-send-habit-form" class="cardItem_details">
                <div class="header">
                    <button class="close_btn" @click="habitItem = false"><i class="fas fa-chevron-down"></i></button>
                    <h2>Start Habit Tracking</h2>
                </div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Select Habit Scenarios</label>
                        <div class="field_wpr" :class="{ 'has-error': errors.habit }">
                            <Field autocomplete="off" name="form" v-model="habit.habit_tracking_ids" :class="{ 'has-error': errors.habit }" rules="required">
                                <multiselect
                                    v-model="habit.habit_tracking_ids"
                                    :options="habits"
                                    value-prop="id"
                                    label="name"
                                    :searchable="true"
                                    placeholder="Select habit scenarios"
                                    mode="tags"
                                ></multiselect>
                            </Field>
                        </div>
                        <ErrorMessage name="form" class="text-danger" />
                    </div>
                </div>
                <div v-show="habit.habit_tracking_ids.length">
                    <schedule-component :schedule-data="habit" title="habit tracking" :close-modal="resetItems" />
                </div>
                <div class="action_wpr fixed_footer">
                    <button type="button" :disabled="bulkActionLoader" class="btn cancel_btn" @click="habitItem = false">Cancel</button>
                    <button :disabled="bulkActionLoader" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="bulkActionLoader"></i> {{ bulkActionLoader ? 'Sending' : 'Send' }}</button>
                </div>
            </Form>
        </div>
        <div v-if="progressItem" class="details_wpr" @click.self="resetItems()">
            <Form @submit="handleStartProgress" v-slot="{ errors }" ref="launchpad-send-form-form" class="cardItem_details">
                <div class="header">
                    <button class="close_btn" @click="progressItem = false"><i class="fas fa-chevron-down"></i></button>
                    <h2>Start Client Tracking</h2>
                </div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Select tracking scenario</label>
                        <div class="field_wpr" :class="{ 'has-error': errors.progress }">
                            <Field autocomplete="off" name="form" v-model="progress.progress_tracking_ids" :class="{ 'has-error': errors.progress }" rules="required">
                                <multiselect
                                    v-model="progress.progress_tracking_ids"
                                    :options="progressTrackings"
                                    value-prop="id"
                                    label="name"
                                    :searchable="true"
                                    placeholder="Select tracking scenario"
                                    mode="tags"
                                ></multiselect>
                            </Field>
                        </div>
                        <ErrorMessage name="form" class="text-danger" />
                    </div>
                </div>
                <div v-show="progress.progress_tracking_ids.length">
                    <schedule-component :schedule-data="progress" title="client tracking" :close-modal="resetItems" />
                </div>
                <div class="action_wpr fixed_footer">
                    <button type="button" :disabled="bulkActionLoader" class="btn cancel_btn" @click="progressItem = false">Cancel</button>
                    <button :disabled="bulkActionLoader" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="bulkActionLoader"></i> {{ bulkActionLoader ? 'Sending' : 'Send' }}</button>
                </div>
            </Form>
        </div>
        <div v-if="filesItem" class="details_wpr" @click.self="resetItems()">
            <Form @submit="handleFileAdd" v-slot="{ errors }" ref="launchpad-send-file-form" class="cardItem_details">
                <div class="header">
                    <button class="close_btn" @click="filesItem = false"><i class="fas fa-chevron-down"></i></button>
                    <h2>Add A File</h2>
                </div>
                <file-component is-list :errors="errors" :contacts="contact.id" ref="file-component" />
                <div class="action_wpr fixed_footer">
                    <button type="button" :disabled="fileLoader" class="btn cancel_btn" @click="filesItem = false">Cancel</button>
                    <button :disabled="fileLoader" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="fileLoader"></i> {{ fileLoader ? 'Adding' : 'Add' }}</button>
                </div>
            </Form>
        </div>
        <div v-if="rewardsItem" class="details_wpr" @click.self="resetItems()">
            <Form @submit="handleRewardSend" v-slot="{ errors }" ref="launchpad-send-reward-form" class="cardItem_details">
                <div class="header">
                    <button class="close_btn" @click="rewardsItem = false"><i class="fas fa-chevron-down"></i></button>
                    <h2>Add Points</h2>
                </div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Points Awarded</label>
                        <div class="field_wpr" :class="{ 'has-error': errors.points }">
                            <Field autocomplete="off" name="points" type="number" min="0" v-model.number="reward.points"  @keyup="reward.points = Number(reward.points).toString();"  rules="required" />
                        </div>
                        <ErrorMessage name="points" class="text-danger" />
                    </div>
                </div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">You Just Earn {{ reward.points }} Points For</label>
                        <div class="field_wpr" :class="{ 'has-error': errors.reason }">
                            <Field autocomplete="off" name="reason" type="text" v-model="reward.reason" placeholder="Type Here..." rules="required" />
                        </div>
                        <ErrorMessage name="reason" class="text-danger" />
                    </div>
                </div>
                <label for="allow" class="switch_option capsule_btn">
                    <h5>Contact Notification</h5>
                    <input type="checkbox" :true-value="1" :false-value="0" id="allow" v-model="reward.subscriber_notification" hidden>
                    <div><span></span></div>
                </label>
                <div v-if="reward.subscriber_notification">
                    <h3>Notification Method</h3>
                    <div class="edit_section">
                        <sending-method v-model="reward.sending_method" />
                    </div>
                </div>
                <div class="action_wpr fixed_footer">
                    <button type="button" :disabled="rewardLoader" class="btn cancel_btn" @click="rewardsItem = false">Cancel</button>
                    <button :disabled="rewardLoader" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="rewardLoader"></i> {{ rewardLoader ? 'Adding' : 'Add' }}</button>
                </div>
            </Form>
        </div>
        <div v-if="journeyItem" class="details_wpr" @click.self="resetItems()">
            <Form @submit="handleStartJourney" v-slot="{ errors }" ref="launchpad-start-journey-form" class="cardItem_details">
                <div class="header">
                    <button class="close_btn" @click="journeyItem = false"><i class="fas fa-chevron-down"></i></button>
                    <h2>Start Journey</h2>
                </div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Journey</label>
                        <div class="field_wpr" :class="{ 'has-error': errors.journey_id }">
                            <Field autocomplete="off" name="journey_id" v-model="journey.journey_ids" :class="{ 'has-error': errors.journey_id }" rules="required">
                                <multiselect
                                    v-model="journey.journey_ids"
                                    :options="journeys"
                                    value-prop="id"
                                    label="name"
                                    :searchable="true"
                                    placeholder="Select journey"
                                    mode="tags"
                                ></multiselect>
                            </Field>
                        </div>
                    </div>
                </div>
                <label v-if="journey.journey_ids.length == 1" for="firststep" class="switch_option capsule_btn">
                    <h5>Start at First Step?</h5>
                    <input type="checkbox" :true-value="1" :false-value="0" id="firststep" v-model="journey.start_first_action" hidden>
                    <div><span></span></div>
                </label>
                <div v-if="!journey.start_first_action && journey.journey_ids.length == 1" class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Assign Step</label>
                        <div class="field_wpr" :class="{ 'has-error': errors.action }">
                            <Field autocomplete="off" name="action" v-model="journey.action" :class="{ 'has-error': errors.action }" rules="required">
                                <multiselect
                                    v-model="journey.action"
                                    :options="journeySteps"
                                    value-prop="step"
                                    label="title"
                                    :searchable="true"
                                    placeholder="Select step"
                                ></multiselect>
                            </Field>
                        </div>
                    </div>
                </div>
                <div class="action_wpr fixed_footer">
                    <button type="button" :disabled="journeyLoader" class="btn cancel_btn" @click="journeyItem = false">Cancel</button>
                    <button :disabled="journeyLoader" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="journeyLoader"></i> {{ journeyLoader ? 'Starting' : 'Start' }}</button>
                </div>
            </Form>
        </div>
        <div v-if="sequenceItem" class="details_wpr" @click.self="resetItems()">
            <Form @submit="handleStartSequence" v-slot="{ errors }" ref="launchpad-send-sequence-form" class="cardItem_details">
                <div class="header">
                    <button class="close_btn" @click="playbookItem = false"><i class="fas fa-chevron-down"></i></button>
                    <h2>Start Sequence</h2>
                </div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Select Sequence</label>
                        <div class="field_wpr" :class="{ 'has-error': errors.sequences }">
                            <Field autocomplete="off" name="sequences" v-model="sequenceForm.sequence_id" rules="required">
                                <multiselect
                                    v-model="sequenceForm.sequence_id"
                                    label="name"
                                    value-prop="id"
                                    :searchable="true"
                                    :options="sequences"
                                    placeholder="Select Sequence"
                                    mode="tags"
                                ></multiselect>
                            </Field>
                        </div>
                        <ErrorMessage name="sequences" class="text-danger" />
                    </div>
                </div>
                <div v-show="sequenceForm.sequence_id.length == 1">
                    <label for="msg-start" class="switch_option capsule_btn">
                        <h5>Start at message 1</h5>
                        <input type="checkbox" id="msg-start" v-model="sequenceForm.msg_start_one" :true-value="1" :false-value="0" hidden>
                        <div><span></span></div>
                    </label>
                    <div class="form_grp" v-show="!sequenceForm.msg_start_one">
                        <div class="group_item">
                            <div class="field_wpr">
                                <multiselect
                                    v-model="sequenceForm.selected_email_order"
                                    label="name"
                                    value-prop="order"
                                    :searchable="true"
                                    :options="sequenceOrders"
                                    placeholder="Published Message 1"
                                >
                              </multiselect>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-show="sequenceForm.sequence_id.length">
                    <label for="sms_throttle" class="switch_option capsule_btn ml-0">
                        <h5>Throttle for contact timezone</h5>
                        <input type="checkbox" id="sms_throttle" v-model="sequenceForm.sms_throttle_tz" :true-value="1" :false-value="0" hidden>
                        <div><span></span></div>
                    </label>
                    <schedule-component :schedule-data="sequenceForm" title="sequence" :close-modal="resetItems" />
                </div>
                <div class="action_wpr mt-5">
                    <button :disabled="bulkActionLoader" type="button" class="btn cancel_btn" @click="sequenceItem = false;">Cancel</button>
                    <button :disabled="bulkActionLoader" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="bulkActionLoader"></i>{{ bulkActionLoader ? ' Starting' : 'Start' }}</button>
                </div>
            </Form>
        </div>
        <div v-if="workoutItem" class="details_wpr" @click.self="resetItems()">
            <Form @submit="handleStartWorkout" v-slot="{ errors }" ref="launchpad-send-form-workout" class="cardItem_details">
                <div class="header">
                    <button class="close_btn" @click="workoutItem = false"><i class="fas fa-chevron-down"></i></button>
                    <h2>Assign Workout</h2>
                </div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Select Workout</label>
                        <div class="field_wpr" :class="{ 'has-error': errors.workout }">
                            <Field autocomplete="off" name="form" v-model="workout.ids" :class="{ 'has-error': errors.workout }" rules="required">
                                <multiselect
                                    v-model="workout.ids"
                                    :options="workouts"
                                    value-prop="id"
                                    label="name"
                                    :searchable="true"
                                    placeholder="Select workout"
                                    mode="tags"
                                ></multiselect>
                            </Field>
                        </div>
                        <ErrorMessage name="form" class="text-danger" />
                    </div>
                </div>
                <template v-if="workout.ids.length">
                    <div class="extra_notex">
                        <label for="add-in-classroom" class="switch_option capsule_btn">
                            <h5>Add in classroom</h5>
                            <input type="checkbox" name="add_workout_in_classroom" :true-value="1" :false-value="0" id="add-in-classroom" v-model="workout.in_classroom" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                    <div class="extra_notex">
                        <label for="add-in-calendar" class="switch_option capsule_btn">
                            <h5>Add in calendar</h5>
                            <input type="checkbox" name="add_workout_in_calendar" :true-value="1" :false-value="0" id="add-in-calendar" v-model="workout.in_calendar" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                    <div class="start_plan" v-if="workout.in_calendar">
                        <div class="mt-2">
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Date</label>
                                    <div class="field_wpr">
                                        <datepicker v-model="workout.calendar_date" :timezone="user.timezone ? user.timezone : 'UTC'" :min-date="moment().format('YYYY-MM-DD')" autoApply placeholder="MM/DD/YYYY" format="MM/dd/yyyy"></datepicker>
                                    </div>
                                </div>
                            </div>
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Time</label>
                                    <div class="group-col-3">
                                        <div class="field_wpr">
                                            <multiselect v-model="workout.calendar_hour" v-bind="hours"></multiselect>
                                        </div>
                                        <div class="field_wpr">
                                            <multiselect v-model="workout.calendar_minute" v-bind="mins"></multiselect>
                                        </div>
                                        <div class="field_wpr">
                                            <multiselect v-model="workout.calendar_ampm" v-bind="clock"></multiselect>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Timezone</label>
                                    <div class="field_wpr">
                                        <multiselect
                                        v-model="workout.timezone"
                                        :options="timezones"
                                        valueProp="timezone"
                                        label="timezone"
                                        :searchable="true"
                                        >
                                            <template v-slot:option="{ option }">
                                                <span>{{ `${ option.abbr} ${ option.utc_offset_str} ${ option.timezone}` }}</span>
                                            </template>
                                        </multiselect>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <schedule-component :schedule-data="workout" title="workout" :close-modal="resetItems" />
                </template>
                <div class="action_wpr fixed_footer">
                    <button type="button" :disabled="bulkActionLoader" class="btn cancel_btn" @click="workoutItem = false">Cancel</button>
                    <button :disabled="bulkActionLoader" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="bulkActionLoader"></i> {{ bulkActionLoader ? 'Sending' : 'Send' }}</button>
                </div>
            </Form>
        </div>
        <div v-if="workoutPlanItem" class="details_wpr" @click.self="resetItems()">
            <Form @submit="handleStartWorkoutPlan" v-slot="{ errors }" ref="launchpad-send-form-workoutplan" class="cardItem_details">
                <div class="header">
                    <button class="close_btn" @click="workoutPlanItem = false"><i class="fas fa-chevron-down"></i></button>
                    <h2>Assign Workout Program</h2>
                </div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Select Workout Program</label>
                        <div class="field_wpr" :class="{ 'has-error': errors.workout }">
                            <Field autocomplete="off" name="workout" v-model="workoutPlanForm.ids" rules="required" label="workout">
                                <multiselect
                                    v-model="workoutPlanForm.ids"
                                    label="name"
                                    value-prop="id"
                                    :searchable="true"
                                    :options="workoutPlans"
                                    placeholder="Select Workout Program"
                                    mode="tags"
                                ></multiselect>
                            </Field>
                        </div>
                        <ErrorMessage name="workout" class="text-danger" />
                    </div>
                </div>
                <div class="starting_plan mt-5 mb-5 workout-plan-evergreen" v-if="workoutPlanForm.ids.length">
                    <h4 class="mb-4">Choose a program assign method</h4>
                    <div class="flex-space-between">
                        <h5>Assign as evergreen <span class="workout-plan-badge">Easy to Scale</span></h5>
                        <label for="start_w1_w2" class="switch_option capsule_btn">
                            <input type="radio" name="evergreen_option" id="start_w1_w2" :value="1"
                                v-model="workoutPlanForm.is_evergreen" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                    <p class="info-text mb-5">This workout program won't appear on your client's calendar. Instead, it will be available for on-demand completion in their programs until you set restrictions.</p>
                    <div class="flex-space-between mt-3">
                        <h5>Assign as date-based <span class="workout-plan-badge">Uses Calendar</span></h5>
                        <label for="start_w1_w3" class="switch_option capsule_btn">
                            <input type="radio" name="evergreen_option" id="start_w1_w3" :value="0"
                                v-model="workoutPlanForm.is_evergreen" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                    <p class="info-text">This workout program will appear on your client's portal calendar and daily task list. This option also enabled client reminders.</p>
                    <div class="mt-3" v-if="workoutPlanForm.is_evergreen == 0">
                        <div class="flex-space-between">
                            <h5>Start on Week 1 Workout 1? </h5>
                            <label for="start_w1_w1" class="switch_option capsule_btn">
                                <input type="checkbox" id="start_w1_w1" :true-value="1" :false-value="0"
                                    v-model="workoutPlanForm.start_type" hidden :disabled="workoutPlanForm.ids.length > 1">
                                <div><span></span></div>
                            </label>
                        </div>
                        <div v-if="workoutPlanForm.start_type">
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Starting on:</label>
                                    <div class="field_wpr">
                                        <multiselect v-model="workoutPlanForm.start_day" :options="startingDay"
                                            placeholder="Select one"></multiselect>
                                    </div>
                                </div>
                            </div>
                            <div class="extra_note" v-if="workoutPlanForm.start_day == 0">
                                Workout plan will be started on Monday - {{ moment(workoutPlanForm.start_date).format('ll') }}
                            </div>
                            <div class="start_plan" v-if="workoutPlanForm.start_day == 1">
                                <date-picker v-model="workoutPlanForm.start_date" :min-date='new Date()'
                                    :disabled-dates="disabledDays" :first-day-of-week="2" is-expanded />
                            </div>
                        </div>
                        <div v-if="!workoutPlanForm.start_type && workoutPlanForm.ids.length == 1">
                            <div class="flex-space-between">
                                <h5>Start this week </h5>
                                <label for="start_this_week" class="switch_option capsule_btn">
                                    <input type="checkbox" id="start_this_week" :true-value="1" :false-value="0"
                                        v-model="workoutPlanForm.start_on_this_week" hidden :disabled="workoutPlanForm.ids.length > 1">
                                    <div><span></span></div>
                                </label>
                            </div>
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Select Starting Week</label>
                                    <div class="field_wpr">
                                        <multiselect v-model="workoutPlanForm.start_week_id" :options="startingWeeks"
                                            placeholder="Select one" value-prop="id" label="name"></multiselect>
                                    </div>
                                </div>
                            </div>
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Select Starting Workout</label>
                                    <div class="field_wpr">
                                        <multiselect v-model="workoutPlanForm.start_workout_id" :options="startingWorkouts"
                                            placeholder="Select one" value-prop="id" label="name"></multiselect>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex-space-between mt-3" v-if="workoutPlanForm.is_evergreen == 0">
                        <label for="start_w1_w4" class="switch_option capsule_btn">
                            <h5>Show program in workout library</h5>
                            <input type="checkbox" name="evergreen_option" id="start_w1_w4" :true-value="1" :false-value="0"
                                v-model="workoutPlanForm.show_in_library" hidden :disabled="workoutPlanForm.ids.length > 1">
                            <div><span></span></div>
                        </label>
                    </div>
                    <div class="additional-options">
                        <h3><span>additional options</span></h3>
                    </div>
                    <div class="flex-space-between" v-show="workoutPlanForm.ids.length">
                        <h5>Add workouts to clients classroom. <span class="workout-plan-badge bagde-blue">Great Bonus</span></h5>
                        <label for="assign-plan-workouts" class="switch_option capsule_btn">
                            <input type="checkbox" :true-value="1" :false-value="0" id="assign-plan-workouts" v-model="workoutPlanForm.add_workouts_in_classroom" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                    <p class="info-text">The individual workouts in this program will also be added to your client's workout library to be completed on demand.</p>
                </div>
                <schedule-component :schedule-data="workoutPlanForm" title="workout plan" :close-modal="resetItems" v-if="1 == 2" />
                <div class="action_wpr fixed_footer">
                    <button type="button" :disabled="bulkActionLoader" class="btn cancel_btn" @click="workoutPlanItem = false">Cancel</button>
                    <button :disabled="bulkActionLoader" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="bulkActionLoader"></i> {{ bulkActionLoader ? 'Sending' : 'Send' }}</button>
                </div>
            </Form>
        </div>
        <div class="section_wpr">
            <h3>Active Tags</h3>
            <div class="no_result" v-if="!contactTags.length">No active tags.</div>
            <ul class="active_tags" v-else>
                <li :class="{ system: tag.tag_type == 1, id_tags: tag.tag_type == 2, trigger: tag.tag_type == 3 }" v-for="(tag, t) of contactTags" :key="t"><span>{{ tag.name }}</span></li>
            </ul>
        </div>
    </div>
    <div v-if="previewNote" class="modal preview_modal right_out">
        <div class="modal_container p-0">
            <div class="modal_header">
                <div class="modal_header border-bottom px-5">
                    <h1>Edit Note</h1>
                    <button type="button" class="close_button" @click="previewNote = false"><i class="fas fa-times"></i></button>
                </div>
            </div>
            <div class="modal_body">
                <div class="edit_section m-0">
                    <edit-note :closeModal="closeNoteModal" :note="previewNoteForm" title="Edit Note"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions, mapGetters } from 'vuex'
    import { DatePicker } from 'v-calendar';

    const TemplateComponent = defineAsyncComponent(() => import('@/components/TemplateComponent'))
    const FileComponent = defineAsyncComponent(() => import('@/components/FileComponent'))
    const TemplateLibrary = defineAsyncComponent(() => import('@/components/TemplateLibrary'))
    const EditNote = defineAsyncComponent(() => import('@/pages/contact/components/profile/EditNote'))
    const PortalMessage = defineAsyncComponent(() => import('@/pages/contact/components/PortalMessage'))
    const ContactEmailComponent = defineAsyncComponent(() => import('@/pages/contact/components/EmailComponent'))
    const ContactSmsComponent = defineAsyncComponent(() => import('@/pages/contact/components/SmsComponent'))
    const ScheduleComponent = defineAsyncComponent(() => import('@/components/Schedule'))

    import moment from 'moment-timezone'
    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'

    export default {
        name: "Launchpad Actions",

        data () {
            return {
                editNote: false,
                noteDropdown : false,
                note: '',
                noteDefaultValue: '',
                noteResetWatcher: 0,
                smsText: '',
                number: '',
                emailText: {},
                email: '',
                smsItem: false,
                emailItem: false,
                tempItem: false,
                playbookItem: false,
                pagesItem: false,
                formsItem: false,
                habitItem: false,
                progressItem: false,
                filesItem: false,
                rewardsItem: false,
                journeyItem: false,
                workoutItem: false,
                workoutPlanItem: false,
                playbook: {
                    contact_id: 0,
                    playbook_id: [],
                    sending_method: 1,
                    contacts: [],
                },
                page: {
                    contact_id: 0,
                    page_id: [],
                    sending_method: 1,
                    add_in_client_portal: 0,
                    contacts: [],
                },
                form: {
                    contact_id: 0,
                    form_id: [],
                    sending_method: 1,
                    add_in_client_portal: 0,
                    contacts: [],
                },
                habit: {
                    habit_tracking_ids: [],
                    contacts: [],
                    action: '/habit-tracking/assign-to-contacts',
                },
                progress:{
                    progress_tracking_ids: [],
                    contacts: [],
                    action: '/progress-tracking/assign-to-contacts',
                },
                journey: {
                    contact_id: 0,
                    journey_ids: [],
                    start_first_action: 1,
                    action: 0,
                    contacts: [],
                },
                reward: {
                    contact_id: 0,
                    points: 0,
                    reason: '',
                    subscriber_notification: 1,
                    sending_method: 1,
                    contacts: [],
                },
                workout:{
                    ids: [],
                    contacts: [],
                    action: '/workout/assign-to-contacts',
                    in_classroom: 1,
                    in_calendar: 0,
                    calendar_date: new Date().toISOString(),
                    calendar_hour: "7",
                    calendar_minute: "00",
                    calendar_ampm: "am",
                    timezone: this.user ? this.user.timezone : '',
                },
                workoutPlanForm: {
                    contacts: [],
                    ids: [],
                    action: '/workout-plan/assign-to-contacts',
                    start_type: 1,
                    start_day: 0,
                    start_date: this.getNextMonday(),
                    start_week_id: 0,
                    start_workout_id: 0,
                    add_workouts_in_classroom: 1,
                    start_on_this_week: 0,
                    is_evergreen: 0,
                    show_in_library: 0,
                },
                startingDay: [
                    { value: 0, label: 'Start on next available Monday?' },
                    { value: 1, label: 'Start on a different Monday' },
                    { value: 2, label: 'Start this week' },
                ],
                startingWeeks: [],
                startingWorkouts: [],
                disabledDays: {
                    weekdays: [1, 3, 4, 5, 6, 7]
                },
                journeySteps: [],
                contactTags: [],
                templateLibrary: false,
                noteParams: {
                    page: 1,
                    per_page: 6,
                    contact_id: 0,
                },
                previewNote: false,
                previewNoteForm: {
                    id: '',
                    notes: '',
                    contact_id: '',
                },
                portalMessage: false,
                sequenceItem: false,
                sequenceForm: {
                    contacts: [],
                    sequence_id: [],
                    action: '/contacts/add-sequence',
                    type: 'leads',
                    msg_start_one: 1,
                    selected_email_order: 0,
                    sms_throttle_tz: 1,
                },
                sequenceOrders: [],
                moment,
                mins: {
                    mode: 'single',
                    value: ['00'],
                    options: [
                        { value: '00', label: '00' },
                        { value: '01', label: '01' },
                        { value: '02', label: '02' },
                        { value: '03', label: '03' },
                        { value: '04', label: '04' },
                        { value: '05', label: '05' },
                        { value: '06', label: '06' },
                        { value: '07', label: '07' },
                        { value: '08', label: '08' },
                        { value: '09', label: '09' },
                        { value: '10', label: '10' },
                        { value: '11', label: '11' },
                        { value: '12', label: '12' },
                        { value: '13', label: '13' },
                        { value: '14', label: '14' },
                        { value: '15', label: '15' },
                        { value: '16', label: '16' },
                        { value: '17', label: '17' },
                        { value: '18', label: '18' },
                        { value: '19', label: '19' },
                        { value: '20', label: '20' },
                        { value: '21', label: '21' },
                        { value: '22', label: '22' },
                        { value: '23', label: '23' },
                        { value: '24', label: '24' },
                        { value: '25', label: '25' },
                        { value: '26', label: '26' },
                        { value: '27', label: '27' },
                        { value: '28', label: '28' },
                        { value: '29', label: '29' },
                        { value: '30', label: '30' },
                        { value: '31', label: '31' },
                        { value: '32', label: '32' },
                        { value: '33', label: '33' },
                        { value: '34', label: '34' },
                        { value: '35', label: '35' },
                        { value: '36', label: '36' },
                        { value: '37', label: '37' },
                        { value: '38', label: '38' },
                        { value: '39', label: '39' },
                        { value: '40', label: '40' },
                        { value: '41', label: '41' },
                        { value: '42', label: '42' },
                        { value: '43', label: '43' },
                        { value: '44', label: '44' },
                        { value: '45', label: '45' },
                        { value: '46', label: '46' },
                        { value: '47', label: '47' },
                        { value: '48', label: '48' },
                        { value: '49', label: '49' },
                        { value: '50', label: '50' },
                        { value: '51', label: '51' },
                        { value: '52', label: '52' },
                        { value: '53', label: '53' },
                        { value: '54', label: '54' },
                        { value: '55', label: '55' },
                        { value: '56', label: '56' },
                        { value: '57', label: '57' },
                        { value: '58', label: '58' },
                        { value: '59', label: '59' },
                    ],
                    createTag: true
                },
                hours: {
                    mode: 'single',
                    value: ['7'],
                    options: [
                        { value: '1', label: '1' },
                        { value: '2', label: '2' },
                        { value: '3', label: '3' },
                        { value: '4', label: '4' },
                        { value: '5', label: '5' },
                        { value: '6', label: '6' },
                        { value: '7', label: '7' },
                        { value: '8', label: '8' },
                        { value: '9', label: '9' },
                        { value: '10', label: '10' },
                        { value: '11', label: '11' },
                        { value: '12', label: '12' },
                    ],
                    createTag: true
                },
                clock: {
                    mode: 'single',
                    value: ['am'],
                    options: [
                        { value: 'am', label: 'AM' },
                        { value: 'pm', label: 'PM' }
                    ],
                    createTag: true
                },
            }
        },

        props: {
            contact: Object,
        },

        components: {
            Field,
            Form,
            ErrorMessage,
            DatePicker,
            TemplateComponent,
            FileComponent,
            TemplateLibrary,
            EditNote,
            PortalMessage,
            ContactEmailComponent,
            ContactSmsComponent,
            ScheduleComponent,
        },

        watch: {
            contact (contact) {
                const vm = this;

                vm.number = contact.number;
                vm.email  = contact.email;
            },

            bulkActionWatcher () {
                const vm = this;

                vm.resetItems();
            },

            'journey.journey_ids' (ids) {
                if (ids.length == 1) {
                    const vm = this;
                    const journeys = JSON.parse(JSON.stringify(vm.journeys));

                    vm.journeySteps = journeys.filter(journey => journey.id == ids[0])[0].actions;
                }
            },

            tagList (tags) {
                const vm = this;

                vm.contactTags = vm.filterTags(tags);
            },

            'sequenceForm.sequence_id' (ids) {
                const vm = this;

                if (ids.length == 1) {
                    vm.resetSequenceOrders(ids[0]);
                }
            },

            'workoutPlanForm.start_week_id' (week) {
                const vm = this;

                vm.parseWorkouts(week);
            },

            'workoutPlanForm.start_day' (day) {
                const vm = this;

                if (day == 0) {
                    vm.workoutPlanForm.start_date  = vm.getNextMonday();
                }

                if (day == 2) {
                    vm.startOnThisWeekAlert();
                }
            },

            'workoutPlanForm.ids' (ids) {
                const vm = this;

                if (ids.length == 1) {
                    vm.parseWeeks();
                }
            },

            workoutItem (item) {
                const vm = this;

                if (item && vm.user.id) {

                    vm.workout.timezone = vm.user.timezone;
                }
            },

            'workoutPlanForm.start_on_this_week' (val) {
                const vm = this;

                if (val)  {
                     vm.startOnThisWeekAlert();
                }
            }
        },

        computed: {
            ...mapState({
                smsLoader: state => state.contactModule.smsLoader,
                emailLoader: state => state.contactModule.emailLoader,
                noteLoader: state => state.contactModule.noteLoader,
                templateLoader: state => state.templateModule.templateLoader,
                bulkActionWatcher: state => state.contactModule.bulkActionWatcher,
                playbookLoader: state => state.playbookModule.playbookSendLoader,
                playbooks: state => state.playbookModule.allPlaybooks,
                pageLoader: state => state.pageModule.pageLoader,
                pages: state => state.pageModule.allPages,
                formLoader: state => state.formModule.formLoader,
                forms: state => state.formModule.allForms,
                fileLoader: state => state.fileModule.fileLoader,
                rewardLoader: state => state.contactModule.rewardLoader,
                journeyLoader: state => state.journeyModule.journeyLoader,
                journeys: state => state.journeyModule.allJourneys,
                tagList: state => state.contactModule.selectedTags,
                notes: state => state.contactModule.notes,
                portalMessageLoader: state => state.contactModule.sendMessageLoader,
                progressTrackings: state => state.progressTrackingModule.allProgressTrackings,
                habits: state => state.habitTrackingModule.allHabitTrackings,
                bulkActionLoader: state => state.contactModule.bulkActionLoader,
                sequences: state => state.sequenceModule.allSequences,
                workouts: state => state.workoutModule.allWorkouts,
                workoutPlans: state => state.workoutModule.allWorkoutPlans,
                timezones: state => state.commonModule.timezones,
                user: state => state.authModule.user,
            }),

            ...mapGetters({
                filterTags: 'tagModule/FILTER_TAGS_TYPE',
            })
        },

        mounted () {
            const vm = this;
            const startDate = vm.getNextMonday();

            vm.number = vm.contact.number;
            vm.email  = vm.contact.email;

            setTimeout(function () {
                vm.getAssets({ contact_id: vm.contact.id });

                if (vm.playbooks.length == 0) {
                    vm.getAllPlaybooks({ select: 'id,title' });
                }

                if (vm.pages.length == 0) {
                    vm.getAllPages({ select: 'id,title' });
                }

                if (vm.forms.length == 0) {
                    vm.getAllForms({ select: 'id,survey_name,is_private' });
                }

                if (vm.journeys.length == 0) {
                    vm.getAllJourneys({ select: 'id,name,action,action_title,action_type' });
                }

                if (vm.progressTrackings.length == 0) {
                    vm.getAllProgressTrackings({ select: 'id,name' });
                }

                if (vm.habits.length == 0) {
                    vm.getAllHabitTrackings({ select: 'id,name' });
                }

                if (vm.sequences.length == 0) {
                    vm.getAllSequences();
                }

                if (vm.workouts.length == 0) {
                    vm.getAllWorkouts({ select: 'id,name' });
                }

                if (vm.workoutPlans.length == 0) {
                    vm.getAllWorkoutPlans().then((result) => {
                        if (result) {
                            vm.workoutPlanForm.start_date  = startDate;

                            if (vm.workoutPlanForm.ids.length == 1) {
                                vm.parseWeeks();
                            }
                        }
                    });
                } else {
                    vm.workoutPlanForm.start_date  = startDate;

                    if (vm.workoutPlanForm.ids.length == 1) {
                        vm.parseWeeks();
                    }
                }
            }, 1500);

            vm.noteParams.contact_id  = vm.contact.id;
            vm.getNotes(vm.noteParams);

            if (vm.timezones.length == 0) {
                vm.getTimezones();
            }
        },

        methods:{
            ...mapActions({
                addNote: 'contactModule/addNote',
                getAllPlaybooks: 'playbookModule/getAllPlaybooks',
                sendPlaybook: 'playbookModule/sendPlaybook',
                getAllPages: 'pageModule/getAllPages',
                sendPage: 'pageModule/sendPage',
                getAllForms: 'formModule/getAllForms',
                sendForm: 'formModule/sendForm',
                sendReward: 'contactModule/sendReward',
                getAllJourneys: 'journeyModule/getAllJourneys',
                startJourney: 'journeyModule/startJourney',
                getAssets: 'contactModule/getAssets',
                getNotes: 'contactModule/getNotes',
                getAllProgressTrackings: 'progressTrackingModule/getAllProgressTrackings',
                getAllHabitTrackings: 'habitTrackingModule/getAllHabitTrackings',
                startHabit: 'habitTrackingModule/startHabit',
                startProgress: 'progressTrackingModule/startProgress',
                getAllSequences: 'sequenceModule/getAllSequences',
                startSequence: 'sequenceModule/startSequence',
                getAllWorkouts: 'workoutModule/getAllWorkouts',
                startWorkout: 'workoutModule/startWorkout',
                getAllWorkoutPlans: 'workoutModule/getAllWorkoutPlans',
                startWorkoutPlan: 'workoutModule/startWorkoutPlan',
                getTimezones: 'commonModule/getTimezones',
            }),

            resetItems () {
                const vm = this;

                vm.smsText      = '';
                vm.note         = '';
                vm.emailText    = {};
                vm.editNote     = false;
                vm.previewNote  = false;
                vm.smsItem      = false;
                vm.emailItem    = false;
                vm.tempItem     = false;
                vm.playbookItem = false;
                vm.pagesItem    = false;
                vm.formsItem    = false;
                vm.filesItem    = false;
                vm.rewardsItem  = false;
                vm.journeyItem  = false;
                vm.habitItem    = false;
                vm.progressItem = false;
                vm.sequenceItem = false;
                vm.workoutItem  = false;
                vm.workoutPlanItem  = false;

                vm.playbook     = {
                                      contacts: [],
                                      playbook_id: [],
                                      sending_method: 1,
                                  };

                vm.page         = {
                                      contacts: [],
                                      page_id: [],
                                      sending_method: 1,
                                      add_in_client_portal: 0,
                                  };

                vm.form         = {
                                      contacts: [],
                                      form_id: [],
                                      sending_method: 1,
                                      add_in_client_portal: 0,
                                  };

                vm.journey      = {
                                      contacts: [],
                                      journey_ids: [],
                                      start_first_action: 1,
                                      action: 0,
                                  };

                vm.reward       = {
                                      contact_id: 0,
                                      points: 0,
                                      reason: '',
                                      subscriber_notification: 1,
                                      sending_method: 1,
                                  };

                vm.habit = {
                    habit_tracking_ids: [],
                    contacts: [],
                    action: '/habit-tracking/assign-to-contacts',
                };

                vm.progress = {
                    progress_tracking_ids: [],
                    contacts: [],
                    action: '/progress-tracking/assign-to-contacts',
                };

                vm.sequenceForm = {
                    contacts: [],
                    sequence_id: [],
                    action: '/contacts/add-sequence',
                    type: 'leads',
                    msg_start_one: 1,
                    selected_email_order: 0,
                    sms_throttle_tz: 1,
                };

                vm.workout = {
                    ids: [],
                    contacts: [],
                    action: '/workout/assign-to-contacts',
                    in_classroom: 1,
                    in_calendar: 0,
                    calendar_date: new Date().toISOString(),
                    calendar_hour: "7",
                    calendar_minute: "00",
                    calendar_ampm: "am",
                    timezone: vm.user ? vm.user.timezone : '',
                };

                vm.workoutPlanForm = {
                    contacts: [],
                    ids: [],
                    action: '/workout-plan/assign-to-contacts',
                    start_type: 1,
                    start_day: 0,
                    start_date: vm.getNextMonday(),
                    start_week_id: 0,
                    start_workout_id: 0,
                    add_workouts_in_classroom: 1,
                    start_on_this_week: 0,
                    is_evergreen: 0,
                    show_in_library: 0,
                };

                vm.startingWeeks     = [];
                vm.startingWorkouts  = [];
            },

            handleAddNote (params, { setFieldError }) {
                const vm = this;
                params.contact_id = vm.contact.id;
                params.setFieldError = setFieldError;
                vm.noteParams.contact_id  = vm.contact.id;

                vm.addNote(params).then((result) => {
                    if (result) {
                        vm.note = '';
                        vm.getNotes(vm.noteParams);
                    }
                });
            },

            handleSMSSend () {
                const vm = this;
                const smsComponent = vm.$refs['sms-component'];

                smsComponent.handleSMSSend();
            },

            handleEmailSend () {
                const vm = this;
                const emailComponent = vm.$refs['email-component'];

                emailComponent.handleEmailSend();
            },

            handleTemplateSend () {
                const vm = this;
                const templateComponent = vm.$refs['template-component'];

                templateComponent.handleTemplateSend();
            },

            handleAssetAction (action, type) {
                const vm = this;
                let message = 'The SMS sending is disabled for this contact.';
                let showWarning = false;
                vm.editNote = false;

                if (type == 1) {
                    message = 'The SMS sending is disabled for this contact.';
                    showWarning = !vm.contact.sms_enabled;

                    if (!showWarning && !vm.contact.number) {
                        // message = 'You can not send an SMS message to this contact because they are missing a phone number!';
                        message = 'This contact does not have a valid mobile number on file.';
                        showWarning = true;
                    }

                } else if (type == 2) {
                    message = 'The email sending is disabled for this contact.';
                    showWarning = !vm.contact.email_enabled;
                } else if (type == 3) {
                    message = 'You need to enable at least one of email and sms sending!';
                    showWarning = !vm.contact.sms_enabled && !vm.contact.email_enabled;
                }

                if (showWarning) {
                    const options = Helper.swalWarningOptions('Oops!', message);
                    Swal.fire(options);
                } else {
                    vm[action] = true;
                }
            },

            handlePlaybookSend (params, { setFieldError }) {
                const vm = this;
                vm.playbook.contacts.push(vm.contact.id);
                vm.playbook.setFieldError = setFieldError;
                vm.sendPlaybook(vm.playbook);
            },

            handlePageSend (params, { setFieldError }) {
                const vm = this;
                vm.page.contacts.push(vm.contact.id);
                vm.page.setFieldError = setFieldError;
                vm.sendPage(vm.page);
            },

            handleFormSend (params, { setFieldError }) {
                const vm = this;
                vm.form.contacts.push(vm.contact.id);
                vm.form.setFieldError = setFieldError;
                vm.sendForm(vm.form);
            },

            handleFileAdd () {
                const vm = this;
                const fileComponent = vm.$refs['file-component'];

                fileComponent.handleFileAdd();
            },

            handleRewardSend (params, { setFieldError }) {
                const vm = this;
                vm.reward.contact_id    = vm.contact.id;
                vm.reward.setFieldError = setFieldError;

                vm.sendReward(vm.reward);
            },

            handleStartJourney (params, { setFieldError }) {
                const vm = this;
                vm.journey.contacts.push(vm.contact.id);
                vm.journey.action         = vm.journeySteps.filter(step => step.step == vm.journey.action)[0];
                vm.journey.setFieldError  = setFieldError;

                vm.startJourney(vm.journey);
            },

            handleTemplateImport (template) {
                const vm = this;

                vm.note = template.content;
                vm.noteDefaultValue = template.content;
            },

            handleNotePagination (page) {
                const vm = this;

                vm.noteParams.page = page;
                vm.getNotes(vm.noteParams);
            },

            closeNoteModal () {
                const vm = this;

                vm.previewNote = false;
            },

            toggleEditNote (note) {
                const vm = this;

                vm.previewNoteForm.id          = note.id
                vm.previewNoteForm.notes       = note.notes
                vm.previewNoteForm.contact_id  = vm.contact.id ? vm.contact.id : '',
                vm.previewNote = true;
            },

            handlePortalMessage () {
                const vm = this;

                vm.portalMessage = true;
            },

            handleSendMessage () {
                const vm = this;

                const portalMessageComponent = vm.$refs['portal-message-component'];

                portalMessageComponent.handleSendMessage();
            },

            closePortalMessage () {
                const vm = this;

                vm.portalMessage = false;
            },

            handleStartHabit (params, { setFieldError }) {
                const vm = this;

                vm.habit.setFieldError = setFieldError;
                vm.habit.contacts.push(vm.contact.id);

                const option      = Helper.swalConfirmOptions('Are You Sure?', "You want to start habit tracking for selected contact?")
                option.preConfirm = () => vm.startHabit(vm.habit).then((result) => {
                                                if (result) {
                                                    vm.resetItems();
                                                    vm.getAssets({contact_id: vm.contact.id})
                                                }
                                            });
                Swal.fire(option);
            },

            handleStartProgress (params, { setFieldError }) {
                const vm = this;

                vm.progress.setFieldError = setFieldError;
                vm.progress.contacts.push(vm.contact.id);

                const option      = Helper.swalConfirmOptions('Are You Sure?', "You want to start client tracking for this contact?")
                option.preConfirm = () => vm.startProgress(vm.progress).then((result) => {
                                            if (result) {
                                                vm.resetItems();
                                                vm.getAssets({contact_id: vm.contact.id})
                                            }
                                        });

                Swal.fire(option);
            },

            resetSequenceOrders (sequenceId) {
                const vm = this;
                const sequence = vm.sequences.find(sequence => sequence.id == sequenceId);

                vm.sequenceOrders = [];

                for (let i = 0; i < sequence.publish_messages; i++) {
                    vm.sequenceOrders.push({ name: `Published Message ${(1 + i)}`, order : i });
                }
            },

            handleStartSequence (params, { setFieldError }) {
                const vm = this;

                vm.sequenceForm.setFieldError = setFieldError;
                vm.sequenceForm.contacts.push(vm.contact.id);

                vm.startSequence(vm.sequenceForm).then((result) => {
                    if (result) {
                        vm.resetItems();
                        vm.getAssets({contact_id: vm.contact.id})
                    }
                });
            },

            handleStartWorkout (params, { setFieldError }) {
                const vm = this;

                vm.workout.calendar_date = moment(vm.workout.calendar_date).format('YYYY-MM-DD')
                vm.workout.setFieldError = setFieldError;
                vm.workout.contacts.push(vm.contact.id);

                if (!vm.workout.in_classroom && !vm.workout.in_calendar) {
                    const option = Helper.swalWarningOptions('Oops!', `Please select at least one option, either in the classroom or on the calendar. `, 'Okay');

                    Swal.fire(option);

                } else {
                    const option      = Helper.swalConfirmOptions('Are You Sure?', "You want to assign workout to selected contact?")
                    option.preConfirm = () => vm.startWorkout(vm.workout).then((result) => {
                                                if (result) {
                                                    vm.resetItems();
                                                    vm.getAssets({contact_id: vm.contact.id})
                                                }
                                            });
                    Swal.fire(option);
                }

            },

            handleStartWorkoutPlan (params, { setFieldError }) {
                const vm = this;

                vm.workoutPlanForm.setFieldError = setFieldError;
                vm.workoutPlanForm.contacts.push(vm.contact.id);

                let planNames = '';

                vm.workoutPlanForm.ids.forEach((id) => {
                    const workout = vm.workoutPlans.filter((w) => w.id == id)[0];

                    if (workout) {
                        if (planNames) {
                            planNames += ', '+workout.name;
                        } else {
                            planNames += workout.name;
                        }
                    }
                })

                const option      = Helper.swalConfirmOptions('Are You Sure?', `You want to enroll ${vm.contact.name} into your ${planNames} workout plan?`)
                option.preConfirm = () => vm.startWorkoutPlan(vm.workoutPlanForm).then((result) => {
                                            if (result) {
                                                vm.resetItems();
                                                vm.getAssets({contact_id: vm.contact.id})
                                            }
                                        });

                Swal.fire(option);
            },

            getNextMonday(date = new Date()) {
                return moment(date).tz('UTC').add(1, 'weeks').isoWeekday(1);

                // const dateCopy = new Date(date.getTime());

                // const nextMonday = new Date(
                //     dateCopy.setDate(
                //         dateCopy.getDate() + ((7 - dateCopy.getDay() + 1) % 7 || 7),
                //     ),
                // );

                // return nextMonday;
            },

            parseWeeks () {
                const vm = this;
                const plan = vm.workoutPlans.filter(plan => plan.id == vm.workoutPlanForm.ids[0]);

                vm.startingWeeks = plan && plan[0] ? plan[0].weeks : [];
            },

            parseWorkouts (id) {
                const vm        = this;
                const week      = vm.startingWeeks.filter(w => w.id == id);
                const workouts  = [];

                if (week && week[0] && week[0].week_settings) {
                    week[0].week_settings.forEach((setting) => {
                        setting.workouts.forEach((workout) => {
                            workouts.push({
                                id: workout.workout_id + '_' + setting.day,
                                name: setting.title.substring(0, 3) + ' - ' + workout.name + ' (' + workout.period + ')'
                            });
                        });
                    });
                }

                vm.startingWorkouts = workouts;
            },

            startOnThisWeekAlert () {

                const option = Helper.swalWarningOptions('Alert!', `By starting the plan this week, ${this.contact.first_name} will get the plan assigned to their calendar this week. If the plan starts on a Monday and today is Wednesday, then it will show as missed they will need to complete it late. This won't affect their tracking, but it will show as completed late.`, 'Okay')

                Swal.fire(option);
            }
        }
    }
</script>

<style scoped>
    .modal_body {
        min-height: 200px;
    }
    .modal_header{
        display: flex;
        flex: 1;
        flex-direction: row;
        align-items: center;
    }
    .close_button {
        border: 0;
        background: rgba(50,55,59,.6);
        font-size: 12px;
        color: #fff;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        min-width: 20px;
    }
    .edit_section {
        padding: 20px;
        flex: 0 1 640px;
        margin: 50px auto;
        border: 1px solid rgb(221, 221, 221);
        background-color: rgb(255, 255, 255);
    }
    .no_result{
        background: #fff;
        border: 1px solid #e9e9e9;
        border-radius: 8px;
        margin-top: 10px;
        padding: 20px;
        font-size: 14px;
        line-height: 17px;
        color: #999;
        text-align: center;
    }

    /* :deep(.email-send-wpr .close_btn.left_out) {
        right: calc(100% - 40px);
        border-radius: 50%;
    } */
    .starting_plan :deep(.vc-container){
        background: transparent
    }
    .extra_note{
        padding: 25px 20px;
        background: #fff;
        border-radius: 5px;
        border: 1px solid #f5f5f5;
        font-size: 13px;
        line-height: 20px;
        font-weight: 500;
        color: #5a5a5a;
        text-align: center;
    }
    .start_plan :deep(.vc-container .vc-day-content) {
        background: #deecff;
        color: #121525 !important;
    }
    .start_plan :deep(.vc-container .vc-day-content:focus) {
        background: #2f7eed;
        color: #fff !important;
    }
    .start_plan :deep(.vc-container .vc-day-content.is-disabled) {
        background: #f1f1f1;
        color: #aaa !important;
    }
</style>
